<template>
  <v-expansion-panels
    v-for="question in popularQuestions"
    :key="question.title"
    variant="accordion"
    :elevation="0"
  >
    <v-expansion-panel
      :bg-color="'transparent'"
      :text="question.text"
      :title="question.title"
    ></v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
const popularQuestions = [
  {
    title: "Як користуватись сервісом",
    text: "Наш сервіс дозволяє вам швидко та зручно знайти потрібні автозапчастини. Просто зареєструйтесь, залиште заявку з описом необхідної деталі, і наші продавці відгукнуться з пропозиціями. Ви можете порівняти ціни та умови, вибрати найкращий варіант та оформити замовлення.",
  },
  {
    title: "Обліковий запис",
    text: "Створення облікового запису дає вам доступ до всіх функцій нашого сервісу. Ви зможете залишати заявки, переглядати історію замовлень, отримувати персоналізовані пропозиції та зберігати улюблені продавці для швидкого доступу в майбутньому.",
  },
  {
    title: "Заявки та замовлення",
    text: "Наш сервіс надає можливість легко залишати заявки на пошук автозапчастин. Ви заповнюєте форму, описуєте необхідну деталь, і продавці зв'язуються з вами з пропозиціями. Після вибору найкращого варіанту, ви оформляєте замовлення та отримуєте необхідну запчастину швидко і зручно.",
  },
  {
    title: "Новини та оновлення",
    text: "Будьте в курсі останніх новин та оновлень нашого сервісу. Ми регулярно інформуємо наших користувачів про нові функції, акції, та інші важливі події. Підпишіться на нашу розсилку, щоб отримувати актуальну інформацію прямо на ваш email.",
  },
  {
    title: "Підтримка клієнтів",
    text: "Наша команда підтримки завжди готова допомогти вам. Якщо у вас виникли питання або потребуєте допомоги, зв'яжіться з нами через чат, електронну пошту або телефонуйте. Ми тут, щоб забезпечити вам найкращий досвід користування нашим сервісом.",
  },
];
</script>

<style scoped lang="scss">
:deep(.v-expansion-panel-title),
:deep(.v-expansion-panel-text__wrapper) {
  padding-left: 0;
  padding-right: 0;
}

:deep(.v-expansion-panel-title__overlay) {
  background-color: transparent;
}

:deep(.v-expansion-panel-title__icon) {
  color: var(--color-secondary-black);
}
</style>
